import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import Link from "../link"
import { StoreConsumer } from "../../store"

const Container = styled.div`
  margin-top: ${rem(50)};

  @media ${(props) => props.theme.largeDown} {
    margin-top: ${rem(25)};
  }
`

const Heading = styled.h3`
  font-size: ${rem(28)};
  font-weight: 400;
  color: ${(props) => props.theme.colorPurple};

  @media ${(props) => props.theme.largeDown} {
    font-size: ${rem(22)};
  }
`

const List = styled.ul`
  margin-top: ${rem(16)};
`

const ListItem = styled.li`
  font-size: ${rem(24)};
  margin-top: ${rem(10)};

  @media ${(props) => props.theme.largeDown} {
    font-size: ${rem(20)};
  }

  a {
    text-decoration: underline;
    color: ${(props) => props.theme.colorPurple};

    strong,
    b {
      letter-spacing: 1px;
    }
  }

  a::before {
    content: "";
    display: inline-block;
    position: relative;
    bottom: ${rem(4)};
    width: ${rem(7)};
    height: ${rem(7)};
    border-radius: ${rem(3.5)};
    background-color: ${(props) => props.theme.colorGreyDark};
    margin-right: ${rem(15)};
  }
`

const RelatedProducts = ({ data }) => {
  return (
    <Container>
      <StoreConsumer>
        {({ translations }) => (
          <Heading
            dangerouslySetInnerHTML={{
              __html: translations[`related_products`],
            }}
          />
        )}
      </StoreConsumer>

      <List>
        {data.map((product, i) => (
          <ListItem key={i}>
            <Link
              to={`/${product.slug}`}
              dangerouslySetInnerHTML={{ __html: product.acf.heroTitle }}
            />
          </ListItem>
        ))}
      </List>
    </Container>
  )
}

RelatedProducts.propTypes = {
  data: PropTypes.array,
}

export default RelatedProducts
