import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"

const Container = styled.section`
  background-color: ${(props) => props.theme.colorGreyDark};

  @media ${(props) => props.theme.mediumDown} {
    padding-top: ${rem(30)};
    padding-bottom: ${rem(30)};
  }
`

const Lists = styled.ul`
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  margin: -1em 0;
`

const Dl = styled.dl`
  display: flex;

  @media ${(props) => props.theme.mediumDown} {
    flex-direction: column;
  }

  &:first-child {
    dt {
      color: ${(props) => props.theme.colorWhite};
    }
  }

  dt {
  }

  dd {
    margin-top: 1em;
    margin-left: ${rem(56)};
    color: ${(props) => props.theme.colorWhite};

    @media ${(props) => props.theme.mediumDown} {
      margin: 0.6em 0;
    }
  }
`

const Data = ({
  data: {
    acf: { items },
  },
}) => {
  if (!items || !items.length) return null

  return (
    <Container>
      <h2 className="is-vhidden">Numbers</h2>

      <Grid>
        <Row>
          <Cell size={23}>
            <Lists>
              {items.map(({ text, number, suffix, prefix }, i) => (
                <Dl key={i}>
                  <dt className="styled-sh2">
                    {prefix}
                    {number}
                    {suffix}
                  </dt>

                  <dd className="styled-p">{text}</dd>
                </Dl>
              ))}
            </Lists>
          </Cell>
        </Row>
      </Grid>
    </Container>
  )
}

Data.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Data
