import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import { rem } from "polished"

import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import RelatedProducts from "./related-products"

const Container = styled.section`
  width: 100%;

  .styled-h4 {
    @media ${(props) => props.theme.largeDown} {
      font-size: ${rem(24)};
    }
  }

  .gatsby-image-wrapper {
    margin-top: ${rem(40)};
  }

  .styled {
    color: ${(props) => props.theme.colorPurpleLight};

    strong,
    b {
      color: ${(props) => props.theme.colorPurple};
      font-weight: normal;
    }
  }
`

const Section = ({
  data: { title, text, image, fullWidth, relatedProducts },
}) => {
  return (
    <Container>
      <Row>
        <Cell size={!fullWidth ? 6 : 11} mq={{ smallDown: { size: 19 } }}>
          <h2 className="styled-h4">{title}</h2>
        </Cell>

        <Cell
          size={!fullWidth ? 11 : 23}
          pushX={!fullWidth && 6}
          mq={{ smallDown: { size: 23, pushX: 0, pushY: 1.5 } }}
        >
          <div
            className="styled styled--large-type"
            dangerouslySetInnerHTML={{ __html: text }}
          />

          {relatedProducts && !!relatedProducts.length && (
            <RelatedProducts data={relatedProducts} />
          )}

          {image && image.localFile && (
            <Img fluid={image.localFile.childImageSharp.fluid} alt={title} />
          )}
        </Cell>
      </Row>
    </Container>
  )
}

Section.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Section
