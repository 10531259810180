import React from "react"
import PropTypes from "prop-types"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Section from "./section"

const TextSections = ({
  data: {
    acf: { sections },
  },
}) => {
  if (!sections || !sections.length) return null

  return (
    <Grid>
      {sections.map((section, i) => (
        <Row
          key={i}
          pushY={i > 0 && 3}
          pullY={i === sections.length - 1 && 2}
          mq={{ xsmallDown: { pullY: i === sections.length - 1 && 5 } }}
        >
          <Section data={section} />
        </Row>
      ))}
    </Grid>
  )
}

TextSections.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TextSections
